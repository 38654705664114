








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TextComponent extends Vue {
  @Prop({
    type: String,
    default: 'primary-text',
    validator: val =>
      ['primary', 'success', 'warning', 'danger', 'info', 'primary-text', 'regular-text', 'secondary-text'].includes(
        val,
      ),
  })
  private color?: string

  @Prop({
    type: String,
    default: '',
  })
  private colorCustom?: string

  @Prop({
    type: String,
    default: '16px',
  })
  private fontSize?: string

  @Prop({
    type: String,
    default: 'normal',
    validator: val => ['normal', 'italic'].includes(val),
  })
  private fontStyle?: string

  @Prop({
    type: [Number, String],
    default: 'normal',
    validator: val => [100, 200, 300, 400, 500, 600, 700, 800, 900, 'bold', 'normal', 'lighter'].includes(val),
  })
  private fontWeight?: number | string

  @Prop({
    type: String,
    default: 'span',
    validator: val => ['span', 'p', 'div'].includes(val),
  })
  private tag?: string

  private get styles(): object {
    return {
      color: this.colorCustom ? this.colorCustom : `var(--color-${this.color})`,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
      fontStyle: this.fontStyle,
    }
  }
}
